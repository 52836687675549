@keyframes slideInRight {
  0% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fade-down{
  0%{
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
  }

  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Spartan", sans-serif; */
}

.section-p1 {
  padding: 40px 80px;
}

#header1{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #142474;
  animation: fade-down 0.5s;
}

 img {
   width: 10%;
   /* height: auto; */
 }

 .active{
  border-bottom: 2px #142474 solid;
 }

 #header1 .text h2{
  color: #142474;
  font-size: 28px;
  font-weight: bold;
  font-family: 'Poppins-semibold', 'poppins' sans-serif;
    
 }

 #header1 .text p{
  color: #142474;
  font-size: 20;
  font-weight: 500;
  font-family: 'Poppins-semibold', 'poppins' sans-serif;
 }

 #header1 button{
  padding: 15px 30px;
  border-radius: 30px;
  background-color: #142474;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
 }

 #header2 {
  display: flex;
  justify-content: space-between;
 }

#header2 .navbar{
  display: flex;
  gap: 30px;
}

#header2 .navbar h5{
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'proxima-nova', sans-serif;
}

/* HOME */
#home{
  background-color: #142474;
  color: #fff;
}

#home-info{
  display: flex;
  justify-content: space-between;
}

#content{
  width: 45%;
  padding: 100px;
  
  }

#content h1{
  font-size: 40px;
  font-family: 'proxima-nova', sans-serif;
  margin-bottom: 30px;
  animation: 1s ease-out 0s 1 slideInLeft;
}

#content p{
  font-size: 16px;
  font-family: 'proxima-nova', sans-serif;
  font-weight: 600;
  line-height: 25px;
  animation: 1s ease-out 0s 1 slideInRight;
}

#video{
  height: 20vh;
}

#video video{
  width: 55vw;
  background-size: cover;
  padding: 0;
  margin-bottom: 150px;
}

#services{
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px;
  flex-wrap: wrap;
  padding: 20px;
}


#services div{
  padding: 30px;
  width: 300px;
  margin-bottom: 20px;
  height: 450px;
  text-align: center;
  background-color: #fff;
  color: #142474;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

#services div h2{
  margin-top: 100px;
  margin-bottom: 40px;
}


#services button{
  background-color: #142474;
  color: #fff;
  padding: 15px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

#training img{
  width: 100%;
  height: 80vh;
  filter: brightness(100%);
}

#contact-us{
  background-color: #142474;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

#contact-us .contact-info h1{
  margin-top: 20px;
  font-size: 70px;
  font-family: 'proxima-nova', sans-serif;
}

#contact-us .contact-info p{
  font-size: 20px;
  font-weight: 300;
  margin-top: 60px;
}

#contact-us form{
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
}

#contact-us form input{
  background-color: transparent;
  width: 100%;
  padding: 20px;
  border-right: none;
  border-top: none;
  border-left: none;
  color: #fff;
  margin-bottom: 20px;
  font-family: 'proxima-nova', sans-serif;
}

#contact-us form textarea{
  background-color: transparent;
  color: #fff;
  border-right: none;
  border-left: none;
  border-top: none;
  font-family: 'proxima-nova', sans-serif;
  padding:10px;
  height: 150px;
  width: 100%;
}

::placeholder{
  color: #fff;
  font-size: 15px;
  font-family: 'proxima-nova', sans-serif;
}

#contact-us form input:hover{
  border:1px solid #fff;
}

#contact-us form textarea:hover {
  border: 1px solid #fff;
}

#contact-us form input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  background: #fff;
  color: #142474;
  border: none;
  width: 30%;
  margin-left: 350px;
  margin-right: 100px;
}

#contact-us button{
  padding: 15px 50px;
  margin-top: 20px;
  margin-left: 70%;
  font-size: 15px;
  cursor: pointer;
}

#contact-us button:hover{
  background-color: rgb(100, 97, 97);
  color: #fff;
}

footer{
  background-color: rgb(245, 241, 237);
  padding: 50px;
  text-align: center;
}

/* SERVICE PAGE */

#service{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 450px;
}

#service .service-text{
  background-color: #142474;
  color: #fff;
  width: 50%;
  height: 450px;
  padding: 150px 190px;
}

#service .service-text h1{
  font-size: 50px;
  font-weight: 900;
  font-family: 'proxima-nova', sans-serif;
  margin-bottom: 20px;
  animation: 1s ease-out 0s 1 slideInLeft;
}

#service .service-text p{
  font-size: 18px;
  font-weight: 100;
  font-family: 'proxima-nova', sans-serif;
  line-height: 30px;
  animation: 1s ease-out 0s 1 slideInLeft;
}

#service .service-img{
  width: 50%;
}

#service .service-img img{
  width: 100%;
  height: 450px;
}

.service2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 450px;
}

.service2 .service-text {
  background-color: #fff;
  color: #1b32a0;
  width: 50%;
  height: 450px;
  padding: 30px 20px;
}

.service2 .service-text h1 {
  font-size: 30px;
  font-weight: 900;
  font-family: 'proxima-nova', sans-serif;
  margin-bottom: 20px;
  animation: 1s ease-out 0s 1 slideInLeft;
}

.service2 .service-text p {
  font-size: 18px;
  font-weight: 100;
  font-family: 'proxima-nova', sans-serif;
  line-height: 30px;
  margin-bottom: 20px;
  animation: 1s ease-out 0s 1 slideInRight;
}

.service2 .service-img {
  width: 50%;
  height: 450px;
  /* filter: brightness(30%); */
}

.service2 .service-img img {
  width: 100%;
  height: 450px;
}

.service2 .service-text button{
  padding: 15px 20px;
  background-color: #1b32a0;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

/* ABOUT */

#about1{
  background-color: #142474;
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 50px;
}

#about1 h1{
  font-size: 60px;
  font-weight: 800;
  font-family: 'proxima-nova', sans-serif;
  /* margin-bottom: 50px; */
}



/* #about2 {
  background-color: #fff;
  color: #142474;
  width: 100%;
  text-align: center;
  padding: 100px 500px;
}

#about2 p{
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 50px;
}

#about2 button{
  padding: 20px 40px;
  border-radius: 20px;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

#about2 button:hover{
  background-color: rgb(218, 216, 215);
} */

#training {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#training .service-text {
  background-color: #fff;
  color: #142474;
  width: 50%;
  height: 450px;
  padding: 150px 190px;
}

#training .service-text h1 {
  font-size: 30px;
  font-weight: 900;
  font-family: 'proxima-nova', sans-serif;
  margin-bottom: 20px;
}

#training .service-text p {
  font-size: 18px;
  font-weight: 100;
  font-family: 'proxima-nova', sans-serif;
  line-height: 30px;
}

#training .service-img {
  width: 50%;
}

#training .service-img img {
  width: 100%;
  height: 450px;
}

#about2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#about2 .service-text {
  background-color: #fff;
  color: #1b32a0;
  width: 50%;
  height: 450px;
  padding: 70px 110px;
}

#about2 .service-text h1 {
  font-size: 30px;
  font-weight: 900;
  font-family: 'proxima-nova', sans-serif;
  margin-bottom: 20px;
  animation: 1s ease-out 0s 1 slideInLeft;
}

#about2 .service-text p {
  font-size: 23px;
  font-weight: 100;
  font-family: 'proxima-nova', sans-serif;
  line-height: 35px;
  margin-bottom: 80px;
  animation: 1s ease-out 0s 1 slideInRight;
  margin-top: 80px;
}

#about2 .service-img {
  width: 50%;
  /* filter: brightness(30%); */
}

#about2 .service-img img {
  width: 100%;
  height: 450px;
}

#about3{
  padding: 50px 180px;
  font-size: 23px;
  font-weight: 100;
  font-family: 'proxima-nova', sans-serif;
  line-height: 35px;
  margin-bottom: 10px;
  /* animation: 1s ease-out 0s 1 slideInRight; */
  background-color: #142474;
  color: #fff;
  text-align: center;
}

@media (max-width:1500px) {

  #header1 img{
    width: 25%;
  }
  #home-info {
      display: block;
      justify-content: space-between;
      height: 1140px;
    }

    #content {
      width: 100%; 
      padding: 30px;
    }
    #video video {
    width: 80vw;  
    margin-left: 10%;
    margin-bottom: 300px;
}

#training .service-text {
  padding: 85px 59px;
}
#contact-us {
 display: block;
}

#contact-us .contact-info {
  margin-bottom: 50px;
}

#header1 button {
  margin-bottom: 10px;
}
#contact-us form input[type="submit"] {
  margin-left: 0px;
}

.service2 .service-text p {
  font-size: 12px;
  line-height: 25px;
}
.service2 .service-text h1 {
  font-size: 20px;
}
.service2 .service-text {
  padding: 10px 10px;
}
#service .service-text {
  padding: 50px 90px;
}

#about3 {
  padding: 30px 100px;
}

#about2 .service-text {
  padding: 10px 10px;
}

#about2 .service-text p {
  font-size: 20px;
}

#about2 .service-img img {
  height: 400px;
}
}

@media (max-width:650px) {
  .service2 {
      display: block;
      justify-content: center;
      align-content: center;
      width: 100%;
      height: 100%;
    }
    .service2 .service-text {
    width: 100%;
    height: 350px;
  }
    .service2 .service-img {
      width: 100%;
      height: 300px;
      margin-bottom: 10px;
    }
    .service2 .service-img img {
    height: 300px;
   }

    #service .service-text {
      height: 300px;
    }
    #service .service-img img {
    height: 300px;
    }
    #service {
    height: 300px;
    }
    #service .service-text {
      padding: 65px 39px;
    }

    #service .service-text h1 {
    font-size: 30px;
    }

    #about2 {
    display: block;
  }

    #about2 .service-text {
      width: 100%;
      height: 300px;
    }

    #about2 .service-img {
    width: 100%;
    /* filter: brightness(30%); */
}
.section-p1 {
  padding: 10px 20px;
}

#about3 {
  padding: 10px 10px;
}
}

@media (max-width:400px) {
  .section-p1 {
      padding: 4px 8px;
      margin-bottom: 20px;
    }


#training .service-text {
  padding: 38px 9px;
}

#contact-us form input[type="submit"] {
  margin-left: 0px;
}

 .service2 .service-text {
   width: 100%;
   height: 400px;
 }

 #service .service-text h1 {
   font-size: 20px;
 }

 #service .service-text p {
   font-size: 13px;
 }

 #about1 h1 {
   font-size: 40px;
 }

 #about3 {
   font-size: 18px;
 }

 #contact-us .contact-info h1 {
   font-size: 35px;
 }

}